import { Controller } from 'stimulus';

window.dispatchMapsEvent = function(...args) {
  const event = document.createEvent('Events');
  event.initEvent('google-maps-callback', true, true);
  event.args = args;
  window.dispatchEvent(event);
};

export default class extends Controller {
  static targets = [ 'map', 'latField', 'lngField', 'useStrictBounds', 'pacInput']
  static values = {
    geocodedAddress: Object,
    defaultLocation: Object,
    defaultCountry: String
  };

  connect() {
    if (window['google'] !== undefined) {
      this.initMap();
    } else {
      window.addEventListener('google-maps-callback', () => this.initMap())
    }
    this.overrideAutocompleteEvents();
  }

  initMap() {
    let initialLocation;
    let zoomLevel;

    if (this.hasGeocodedAddressValue && this.isLocationValid(this.geocodedAddressValue)) {
      const { latitude, longitude } = this.geocodedAddressValue;
      initialLocation = new google.maps.LatLng(latitude, longitude);
      zoomLevel = this.geocodedAddressValue.zoomLevel;
    } else {
      const { latitude, longitude } = this.defaultLocationValue;
      initialLocation = new google.maps.LatLng(latitude, longitude);
      zoomLevel = this.defaultLocationValue.zoomLevel;
    }

    this.map(initialLocation, zoomLevel);
  }

  isLocationValid(location) {
    return location && location.latitude !== null && location.longitude !== null
  }

  map(location, zoomLevel) {
    if (this._map === undefined && location !== undefined) {
      const mapOptions = {
        center: location,
        zoom: zoomLevel,
        zoomControl: true,
        scaleControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        clickableIcons: false,
        mapId: "7add3992dbbda121",
        zoomControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.RIGHT_CENTER
        }
      };

      this._map = new google.maps.Map(this.mapTarget, mapOptions);

      this._map.addListener('click', this.mapEventListeners().clickEvent);
      this.initSearchInput();
    }
    return this._map;
  }

  mapEventListeners() {
    return {
      clickEvent: (mapsMouseEvent) => {
        this.addMarker(mapsMouseEvent.latLng);
        this.latFieldTarget.value = mapsMouseEvent.latLng.lat();
        this.lngFieldTarget.value = mapsMouseEvent.latLng.lng();
        this._map.panTo(mapsMouseEvent.latLng);
      }
    };
  }

  async addMarker(location) {
    if (this._marker === undefined) {
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
      this._marker = new AdvancedMarkerElement({
        map: this._map,
        position: location,
      });
    } else {
      this._marker.position = location
    }
  }

  initSearchInput() {
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
      componentRestrictions: { country: this.defaultCountryValue },
    };

    this.autocomplete = new google.maps.places.Autocomplete(this.pacInputTarget, options);
    this.autocomplete.bindTo("bounds", this._map);
  }

  searchLocation(event) {
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        window.alert(
          "No details available for input: '" + place.name + "'"
        );
        return;
      }

      if (place.geometry.viewport) {
        this._map.fitBounds(place.geometry.viewport);
      } else {
        this._map.setCenter(place.geometry.location);
        this._map.setZoom(17);
      }

      this.addMarker(place.geometry.location);
      this.latFieldTarget.value = place.geometry.location.lat();
      this.lngFieldTarget.value = place.geometry.location.lng();
    });

    this.pacInputTarget.focus();
  }

  positionMapLocation(event) {
    event.preventDefault();
    let dataLocation = JSON.parse(event.currentTarget.dataset.location);
    let location = new google.maps.LatLng(dataLocation.latitude, dataLocation.longitude);
    let zoomLevel = dataLocation.zoomLevel;
    this._map.panTo(location);
    this._map.setZoom(parseInt(zoomLevel));
  }

  overrideAutocompleteEvents() {
    this.pacInputTarget.addEventListener('input', (event) => event.stopPropagation(), true);
    this.pacInputTarget.addEventListener('keydown', (event) => event.stopPropagation(), true);
    this.pacInputTarget.addEventListener('keyup', (event) => event.stopPropagation(), true);
  }
}
